window.$ = require('jquery');
window.Vue = require('/apps/static/js/vue.js');
window.axios = require('axios');
require('/apps/static/js/vue-clipboard.min.js');
require('selectize');
require('/apps/static/js/project.js');
require('/apps/static/js/vue-readux.js');
window.UIkit = require('uikit');
window.UIkitIcons = require('/apps/static/js/uikit-icons.min.js');
require('/apps/static/js/project.js');
